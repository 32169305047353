import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { schedulePageData } from "../../services/apiRequests.js"
//GATSBY PAGE SEO + LAYOUT
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
import CalendarColumn from "../../components/molecules/schedule_calendarColumn.js"
import DateContent from "../../components/molecules/schedule_dateContent.js"

// PAGE COMPONENTS AND TEMPLATES.
import { InPageNav } from "../../components/navbar.js"
// import { CtaPrimary } from "../../components/elements/buttons.js"
import { Section } from "../../components/sections/sections.js"
import {
  HeroTitleH1,
  // SectionTitle,
  // SectionDescription,
} from "../../components/typography.js"

// const HeroKeyframes = keyframes `
//   0%{background-position:40% 0%}
//   50%{background-position:61% 100%}
//   100%{background-position:40% 0%}
// `
const Hero = styled(Section)`
  // background: linear-gradient(310deg, #4dcdac, #e38f34, #51af18);

  // background-size: 1000% 1000%;
  height: 90vh;
  position: relative;
  top: -31vh;
`
const Wrapper = styled.div`
  width: 95%;
  height: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 10px;
  align-items: stretch;
  margin: 0 auto;
  background-color: rgb(223, 234, 245, 0.6);
  backdrop-filter: blur(50px);
`

const AdjSection = styled(Section)`
  margin-top: -70vh;
`

const sampleData = [
  {
    name: "CASPer",
    type: "online",
    package: "Flex",
    startDate: "2020-01-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "Sample Test",
        days: 1,
        startDate: "2020-01-25T00:00:00.000Z",
        endDate: "2020-02-04T00:00:00.000Z",
      },
      {
        subject: "Frameworks",
        days: 1,
        startDate: "2020-02-04T00:00:00.000Z",
        endDate: "2020-02-08T00:00:00.000Z",
      },
      {
        subject: "Framework Appliction",
        days: 3,
        startDate: "2020-02-08T00:00:00.000Z",
        endDate: "2020-02-12T00:00:00.000Z",
      },
    ],
    status: "available",
  },
]
const McatSchedule = () => {
  const [courseData, setData] = useState(() =>
    process.env.NODE_ENV === "production" ? [] : sampleData
  )
  const [showContent, updateShow] = useState(0)
  const RedirectToPage = dest => {
    navigate(dest)
  }
  useEffect(() => {
    if (courseData.length === 0) {
      schedulePageData("CASPer", data => {
        setData(data)
      })
    }
  }, [showContent, updateShow, courseData.length])

  return (
    <Layout navColor="transparent">
      <SEO title="99point9 MCAT Calendar" />
      <InPageNav
        pageTitle="MCAT Schedule"
        navItems={[
          { link: "/casper", label: "Overview" },
          { link: "/casper/how_it_works", label: "How It Works" },
          { link: "/casper/schedule", label: "Class Schedule" },
          { link: "/casper/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <Hero wide>
        <HeroTitleH1 align="center" float="center">
          SCHEDULE
        </HeroTitleH1>
      </Hero>

      <AdjSection wide>
        <Wrapper>
          {courseData.length > 0 ? (
            <>
              <CalendarColumn
                data={courseData}
                updateContentCb={index => {
                  updateShow(index)
                }}
              />
              <DateContent data={courseData[showContent]} />
            </>
          ) : null}
        </Wrapper>
      </AdjSection>
    </Layout>
  )
}
export default McatSchedule
